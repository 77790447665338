

























.started-box {
  @media (min-width: 768px) {
    min-height: 370px;
    padding: 0 64px;
  }
  min-height: 375px;
  padding: 20px 0;
  background: #000;
  color: #fff;
  align-items: center;

  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;
  padding: 16px;
  position: relative;
  box-sizing: border-box;
  .bg-img {
    img {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 100;
      right: 0;
      object-fit: cover;
    }
  }
  .bg-color {
    // background: linear-gradient(46deg, black 35%, rgba(0, 0, 0, 0) 48%);
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 200;
  }
  .text {
    width: 100%;
    max-width: fit-content;
    position: relative;
    margin: 0 auto;
    z-index: 300;
    h2.title {
      font-size: 2.725rem;
      font-weight: 400;
      line-height: 3.0875rem;
      margin-bottom: 24px;
      text-align: center;
    }
    .detail {
      font-size: 1.125rem;
      text-align: initial;
      margin-bottom: 0;
    }
    .button-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .button {
        letter-spacing: 0.05rem;
        position: relative;
        color: #fff !important;
        min-width: 150px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
        border-radius: 0.3rem;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 10px;
        max-width: 300px;

        &.btn-register {
          background: #009750;
          padding: 15px 10px;
          min-height: 55px;
          border-radius: calc(55px * 1.5);
          text-decoration: none;
          &:hover,
          &:focus,
          &:focus-visible {
            box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .bg-color {
      background: linear-gradient(26deg, #000000 10%, rgba(0, 0, 0, 0) 60%);
    }
  }
  @media (max-width: 1440px) {
    min-height: 275px;
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.9rem);
        margin-bottom: 5px;
      }
      .detail {
        font-size: 1rem;
        margin-bottom: 0 !important;
      }
      .button-section {
        .btn-register {
          margin: 5px 0 10px;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .detail {
        text-align: center;
      }
      .button-section {
        margin: 10px auto;
      }
    }
  }
}
